@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Roboto Thin';
  src: local('Roboto Thin'), url('./assets/fonts/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Light';
  src: local('Roboto Light'), url('./assets/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Regular';
  src: local('Roboto Regular'), url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Medium';
  src: local('Roboto Medium'), url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Bold';
  src: local('Roboto Bold'), url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Black';
  src: local('Roboto Black'), url('./assets/fonts/Roboto-Black.ttf') format('truetype');
}

* {
  box-sizing: border-box;
}
